export const ApiResources = {
 'getUserDetails': 'http://localhost:61761/api/',
 'getProducts': 'http://localhost:61761/api/admin/',
 'getUserProducts': 'http://localhost:61761/api/user/',
 'getProductNames': 'http://localhost:61761/api/',
 'getProductImagesById': 'http://localhost:61761/api/',
 'saveProduct': 'http://localhost:61761/api/',
 'updateProduct': 'http://localhost:61761/api/',
 'deleteProduct': 'http://localhost:61761/api/',
 'getProductDetails': 'http://localhost:61761/api/',
 'getPermissionSet' : 'http://localhost:61761/api/',
 'getRoles': 'http://localhost:61761/api/',
 'saveRole': 'http://localhost:61761/api/',
 'updateRole': 'http://localhost:61761/api/',
 'deleteRole': 'http://localhost:61761/api/',
 'getRoleGroups': 'http://localhost:61761/api/',
 'saveRoleGroups': 'http://localhost:61761/api/',
 'getPermissions': 'http://localhost:61761/api/',
 'getSortByPermissions': 'http://localhost:61761/api/',
 'savePermission': 'http://localhost:61761/api/',
 'updatePermission': 'http://localhost:61761/api/',
 'deletePermission': 'http://localhost:61761/api/',
 'saveCatalog': 'http://localhost:61761/api/',
 'getCatalogSearch': 'http://localhost:61761/api/',
 'getUserCatalogSearch': 'http://localhost:61761/api/',
 'deleteCatalog': 'http://localhost:61761/api/',
 'updateCatalog': 'http://localhost:61761/api/',
 'getImage': 'http://localhost:61761/',
 'getThumbnail': 'http://localhost:61761/',
 'getBrandSearch': 'http://localhost:61761/api/',
 'saveBrand': 'http://localhost:61761/api/',
 'updateBrand': 'http://localhost:61761/api/',
 'deleteBrand': 'http://localhost:61761/api/',
 'getCategorySearch': 'http://localhost:61761/api/',
 'saveCategory': 'http://localhost:61761/api/',
 'updateCategory': 'http://localhost:61761/api/',
 'deleteCategory': 'http://localhost:61761/api/',
 'getMediaSearch': 'http://localhost:61761/api/',
 'saveMedia': 'http://localhost:61761/api/',
 'updateMedia': 'http://localhost:61761/api/',
 'deleteMedia': 'http://localhost:61761/api/',
 'getProductOptionSearch': 'http://localhost:61761/api/',
 'saveProductOption': 'http://localhost:61761/api/',
 'updateProductOption': 'http://localhost:61761/api/',
 'deleteProductOption': 'http://localhost:61761/api/',
 'getUserRoles': 'http://localhost:61761/api/',
 'saveUserRoles': 'http://localhost:61761/api/',
 'updateUserRoles': 'http://localhost:61761/api',
 'saveUserDetails': 'http://localhost:61761/api/',
 'getPortalUserDetails': 'http://localhost:61761/api/admin/',
 'savePortalUserDetails': 'http://localhost:61761/api/admin/',
 'updatePortalUserDetails': 'http://localhost:61761/api/admin/',
 'deletePortalUserDetails': 'http://localhost:61761/api/admin/',
 'getUserSettings': 'http://localhost:61761/api/',
 'saveUserSettings': 'http://localhost:61761/api/',
 'smsMasterSettings' : 'http://localhost:61761/api/',
 'emailMasterSettings' : 'http://localhost:61761/api/',
 'getsortbypermission' : 'http://localhost:61761/api/',
};
