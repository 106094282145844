import { Attribute, forwardRef, Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[dateComparison][formControlName]',
    providers: [{ provide: NG_VALIDATORS, useExisting:  DateComparisonValidatorDirective, multi: true }]
})
export class DateComparisonValidatorDirective implements Validator {
    constructor(@Attribute('dateComparison') public dateComparison: string,
                @Attribute('dateToCompare') public dateToCompare: string) { }
    validate(c: AbstractControl): { [key: string]: any } {
        const dateComparisonRef = c.parent.get(this.dateComparison);
        const dateToCompareRef = c.parent.get(this.dateToCompare);
        if (dateToCompareRef && dateComparisonRef) {
            const dateToCompare = new Date(dateToCompareRef.value.year, dateToCompareRef.value.month, dateToCompareRef.value.day);
            const dateComparisonVal = new Date(dateComparisonRef.value.year, dateComparisonRef.value.month, dateComparisonRef.value.day);
            if (dateToCompare >= dateComparisonVal) {
                return { dateComparison: true };
            } else {
                 dateToCompareRef.setErrors(null);
            }
        }
        return null;
    }
}
