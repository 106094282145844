
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { SharedService } from '../shared.Service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private userLoggedIn = new Subject<boolean>();

  constructor(private sharedService: SharedService) {
    this.userLoggedIn.next(false);
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }
  getSesstion(): Observable<any> {
    const observable = new Observable((observer) => {
      const res = this.sharedService.appUserId !== '' ? true : false;
      observer.next(res);
    });
    return observable;
  }
}
