import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Response } from 'app/shared/models/response/response.model';
import { ApiDictionary, Resources } from '../../constants/';
import { HttpErrorResponse } from '@angular/common/http';
import { Product } from 'app/shared/models/product/product.model';
import { environment } from 'environments/environment';
import { DatePipe } from '@angular/common';
@Injectable()

export class ProductService implements OnInit {
    static GET_PATH = `${environment.apiPath}` + 'getproductbysearchcriteria';
    static GET_PATH1 = `${environment.apiPath}` + 'getproductdetails';
    static GET_PATH2 = `${environment.apiPath}` + 'getbranddetails';
    static GET_PATH3 = `${environment.apiPath}` + 'getproductstatustypes';
    static GET_PATH4 = `${environment.apiPath}` + 'getpurityvalues';
    static SAVE_PATH = `${environment.apiPath}` + 'saveproduct';
    static UPDATE_PATH = `${environment.apiPath}` + 'updateproduct';
    static DELETE_PATH = `${environment.apiPath}` + 'deleteproduct';
    constructor(private apiService: ApiService, private datepipe: DatePipe) { }
    ngOnInit() {

    }

    saveProduct(product: Product): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.postData(ProductService.SAVE_PATH, product).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = { failure: true, error:
                        environment.errorMessages[error.error.Code || error.error.Message], success: false };
                }
                observer.next(res);
            });
        });
        return observable;
    }

    updateProduct(product: Product): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.updateData(ProductService.UPDATE_PATH, product).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = { failure: true, error: result.error, success: false };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true,
                        result: product.ProductName + environment.successMessages.Update_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = { failure: true, error: 
                        environment.errorMessages[error.error.Code || error.error.Message], success: false };
                }
                observer.next(res);
            });
        });
        return observable;
    }

    deleteProduct(id: number, status: boolean, name: string): Observable<any> {
        let apiUrl = ProductService.DELETE_PATH;
        apiUrl = apiUrl + '/' + id + '/' + (!status);
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = { failure: true, error: result.error, success: false };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: name + (!status
                            ? environment.successMessages.Activate_Success
                            : environment.successMessages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = { failure: true, error: environment.errorMessages[error.Code || error.code], success: false };
                }
                observer.next(res);
            });
        });
        return observable;
    }
    searchProduct(searchObj: any): Observable<any> {
        const observable = new Observable((observer) => {
            const request = {
                Name: searchObj.name, ProductCode: searchObj.code, CategoryId: searchObj.categoryId, IsActive: searchObj.status,
                MinValue: searchObj.minPrice !== '' ?  searchObj.minPrice : 0,
                MaxValue:  searchObj.minPrice !== '' ? searchObj.minPrice : 0,
                FromDate: new Date(searchObj.fromdate.year, searchObj.fromdate.month - 1,
                    searchObj.fromdate.day).toDateString(),
                ToDate: new Date(searchObj.todate.year, searchObj.todate.month - 1,
                    searchObj.todate.day).toDateString(),
            };
            this.apiService.postData(ProductService.GET_PATH, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateProductList(result.Products), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: environment.errorMessages[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }
    getBrand(): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.getDetails(ProductService.GET_PATH2).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    observer.next(result);
                }
            }, (error) => {
                const response: Response = { failure: true, error: environment.errorMessages[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }
    getProductStatusTypes(): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.getDetails(ProductService.GET_PATH3).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    observer.next(result);
                }
            }, (error) => {
                const response: Response = { failure: true, error: environment.errorMessages[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }
    getPurityValues(): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.getDetails(ProductService.GET_PATH4).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populatePurityValues(result), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: environment.errorMessages[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }
    private populatePurityValues(purity: any): any {
        const purityList = [];
        for (let index = 0; index < purity.length; index++) {
            const value = {
                id: purity[index].ValueId,
                optionId: purity[index].OptionId,
                purity: Number (purity[index].ValueName),
            };
            purityList.push(value);
        }
        return purityList;
    }
    private populateProductList(products: any): any {
        const productList = [];
        for (let index = 0; index < products.length; index++) {
            const product = {
                sNo: index + 1 ,
                id: products[index].ProductId,
                product: products[index].ProductName,
                shortname: products[index].ShortName,
                hsnCode: products[index].HSNCode,
                gstRate: products[index].GSTRate,
                unitPrice: products[index].UnitPrice,
                code: products[index].ProductCode,
                brandId: products[index].BrandId,
                statusId: products[index].StatusCode,
                categoryId: products[index].CategoryId,
                category: products[index].CategoryName,
                startDate: products[index].StartDate,
                createdDate: this.datepipe.transform(products[index].CreatedOn, 'yyyy-MM-dd'),
                isActive: products[index].IsActive ? 'Yes' : 'No',
                description: products[index].Description,
                longDescription: products[index].LongDescription,
                productsku: this.populateProductskus(products[index]),
                productskuValues: this.populateProductSkuValues(products[index])
            };
            productList.push(product);
        }
        return productList;
    }
    private populateProductskus(product: any) {
        for (let index = 0; index < product.ProductSKUs.length; index++) {
            const element = product.ProductSKUs[index];
            const sku = {
                skuId: element.SkuId,
                sku: element.Sku,
                mrp: element.MRP,
                isActive: element.IsActive,
                startDate: element.StartDate ?
                    element.StartDate.indexOf('T') ? new Date(element.StartDate.substr(0, element.StartDate.indexOf('T')))
                        : new Date(element.StartDate) : null,
                endDate: element.EndDate ?
                    element.EndDate.indexOf('T') ? new Date(element.EndDate.substr(0, element.EndDate.indexOf('T')))
                        : new Date(element.EndDate) : null
            };
            return sku;
        }
    }

    private populateProductSkuValues(product: any) {
        if (product.ProductSKUValues.length === 0) {
            const element = product.ProductSKUValues;
            const skuValue = {
                optionId: 6,
                valueId: element.ValueId,
                skuId: element.SkuId,
                sku: element.Sku,
                optionName: element.OptionName,
                valueName: '',
            };
            return skuValue;
        } else {
            for (let index = 0; index < product.ProductSKUValues.length; index++) {
                const element = product.ProductSKUValues[index];
                const skuValue = {
                    optionId: element.OptionId,
                    valueId: element.ValueId,
                    skuId: element.SkuId,
                    sku: element.Sku,
                    optionName: element.OptionName,
                    valueName: element.ValueName,
                    id: index + 1
                };
                return skuValue;
            }
        }
    }

}
