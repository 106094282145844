import { environmentversion as environmentversion } from './environment.version'
export const environment = {
    production: false,
    log: true,
    flags: {
        useNewHeader: true
    },
    ...environmentversion,
    sessionTokenKey: 'token',
    refreshTokenKey: 'refreshToken',
    userId : 'userId',
    appUserId : 'appUserId',
    apiPath: 'http://dgsonline-api.kpvsolutions.com/api/v3/',
    pageLimit: 10, // Grid Page Display Limit
    maxDateYear: 10, // Maximum date year for date picker
    thumbnailSize: '512', // Maximum thumbnail size
    iconSize: '1024', // Maximum icon size,
    sessionTimeout: 900, // maximum time in seconds 
    pageSize: 20, // Maximum records to be displayed on list page for one request
    errorMessages: {
      '500': 'Due to technical problem unable to serve the request, try after sometime..',
      '404': 'Unable to serve your request, resource you requested is not valid..',
      '408': 'Request timeout',
      '400': 'Due to technical problem unable to serve your request, try again',
      '0': 'Not connected to internet'
    },
    successMessages: {
      Reset_Success_Message: 'Password reset is successfully completed',
      Change_Password_Success_Message: 'Your password is changed successfully',
      Forgot_Password_Success_Message: 'Your password is changed successfully',
      Signup_Suceess_Message: 'Your registration is successfully completed, login to your account',
      Forgot_Password_Initiated_Message: 'Verification code sent to your registered mobile number.',
      Profile_Update: 'Profile updated successfully',
      Save_Success: ' is Saved successfully',
      Permission_Group_Save_Success: 'Permission group saved successfully',
      Update_Success: ' is Updated successfully',
      Activate_Success: ' is activated successfully',
      Deactivate_Success: ' is deactivated successfully',
      Delete_Success: ' is deleted successfully',
      User_Role_Success_Message: 'roles added successfully to ',
    },
  };
