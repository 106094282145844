import { Injectable } from '@angular/core';
import { ApiDictionary, Resources } from '../../constants';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Response } from 'app/shared/models/response/response.model';
import { environment } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { PermissionSet } from 'app/shared/constants/permissions/permission.constants';
import { UserPermission } from 'app/shared/models/permission/userPermissions.model';
import { SortByPermissions, SavePermission } from 'app/shared/models/permission/permissions.Model';
import { RouteInfo } from 'app/shared/sidebar/sidebar.metadata';
import { SharedService } from '../shared.Service';

@Injectable()
export class PermissionService {
    static GET_PATH = `${environment.apiPath}` + 'getpermissions';
    static GET_PATH1 = `${environment.apiPath}` + 'getsortbypermissionsbystatus';
    static GET_PATH6 = `${environment.apiPath}` + 'getsortbypermissionsbystatusbysubmenu';
    static GET_PATH2 = `${environment.apiPath}` + 'permissionset';
    static GET_PATH3 = `${environment.apiPath}` + 'permissionsetbyroleid';
    static GET_PATH4 = `${environment.apiPath}` + 'getsortbysubpermissionsbystatus';
    static GET_PATH5 = `${environment.apiPath}` + 'getrolepermissions';
    static GET_PATH7 = `${environment.apiPath}` + 'getrolesbypermissionid';
    static SAVE_PATH = `${environment.apiPath}` + 'savepermission';
    static UPDATE_PATH = `${environment.apiPath}` + 'updatepermission';
    static DELETE_PATH = `${environment.apiPath}` + 'deletepermission';
    constructor(private apiService: ApiService) { }
    private getRolePermissions(role: string) {
        let permissionSet = [];
        switch (role.toLowerCase()) {
            case 'superadmin': permissionSet = PermissionSet.SuperAdminPermissionset;
                break;
            case 'backendadmin':
            case 'admin': permissionSet = PermissionSet.BackendAdminPermissionset;
                break;
            case 'operator': permissionSet = PermissionSet.OperatorPermissionset;
                break;
            default: break;
        }
        return permissionSet;
    }

    hasPermission(module: string, permission: string, permissionSet: any): boolean {
        let result = false;
        if (permissionSet.length > 0) {
            for (let index = 0; index < permissionSet.length; index++) {
                const permissionItem = permissionSet[index];
                if (permissionItem.title.toLowerCase() === module) {
                    result = permissionItem[permission];
                    break;
                }
            }
        }
        return result;
    }

    getNavigationMenus(permissionSet: any) {
        const sideMenus = [];
        const badgeClass = 'badge badge-pill badge-danger float-right mr-1 mt-1';
        const classs = 'has-sub';
        permissionSet.forEach(element => {
            if (element.canMenuItem) {
                const permission: RouteInfo = {
                    path: element.path,
                    title: element.title,
                    badge: '',
                    icon: element.icon,
                    isExternalLink: false,
                    class: element.submenu.length > 0 ? classs : '',
                    badgeClass: element.submenu.length > 0 ? badgeClass : '',
                    submenu: this.naviGateSubMenus(element.submenu || [])
                };
                sideMenus.push(permission);
            }
        });
        return sideMenus;
    }
    // getNavigationMenus(permissionSet: any) {
    //     if (permissionSet.length > 0) {
    //         permissionSet = permissionSet.filter((element) => {
    //             return element.canMenuItem;
    //         });
    //     }
    //     return permissionSet || [];
    // }
    getMenusByUserIdDeviceType(userId: string, deviceType: string): Observable<any> {
        let permissionSetUrl = PermissionService.GET_PATH2;
        permissionSetUrl = permissionSetUrl + '/' + userId + '/' +
            (deviceType === 'mobile' ? true : false);
        //   const param = this.apiService.BindParamsToUrl(permissionSetUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getDetails(permissionSetUrl).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const response: Response = {
                        failure: false, success: true,
                        result: this.buildPermisisonList(result.PermissionSet), error: null
                    };
                    observer.next(response);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = environment.errorMessages[error.error.Code || error.error.Status || error.error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
    getPermissionByRoleId(searchObj: any): Observable<any> {
        const observable = new Observable((observer) => {
            const request = { RoleId: searchObj };
            this.apiService.postData(PermissionService.GET_PATH3, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const response: Response = {
                        failure: false, success: true,
                        result: this.buildPermisisonList(result.PermissionSet), error: null
                    };
                    observer.next(response);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = environment.errorMessages[error.error.Code || error.error.Status || error.error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    buildPermisisonList(permissionSet: Array<any>) {
        const permissions = [];
        permissionSet.forEach(element => {
            const permission: UserPermission = {
                id: element.Id,
                title: element.Name,
                canCreate: element.CanCreate,
                canDelete: element.CanDelete,
                canMenuItem: element.CanMenuItem,
                canUpdate: element.CanUpdate,
                canView: element.CanView,
                icon: element.WebIcon,
                path: element.WebUrl,
                sortBy: element.SortBy,
                class: element.SubPermission > 0 ? 'has-sub' : '',
                badgeClass: '',
                submenu: this.populateSubMenus(element.SubPermission || [])
            };
            permissions.push(permission);
        });
        return permissions;
    }
    private populateSubMenus(permissionSet: Array<any>) {
        const permissions = [];
        permissionSet.forEach(element => {
            const permission: UserPermission = {
                id: element.Id,
                canCreate: element.CanCreate,
                canDelete: element.CanDelete,
                canMenuItem: element.CanMenuItem,
                canUpdate: element.CanUpdate,
                canView: element.CanView,
                icon: element.WebIcon,
                path: element.WebUrl,
                sortBy: element.SortBy,
                class: '',
                badgeClass: '',
                title: element.Name,
                submenu: []
            };
            permissions.push(permission);
        });
        return permissions;
    } private naviGateSubMenus(permissionSet: Array<any>) {
        const permissions = [];
        permissionSet.forEach(element => {
            const permission: UserPermission = {
                id : element.Id,
                canCreate: element.canCreate,
                canDelete: element.canDelete,
                canMenuItem: element.canMenuItem,
                canUpdate: element.canUpdate,
                canView: element.canView,
                icon: element.icon,
                path: element.path,
                sortBy: element.sortBy,
                class: '',
                badgeClass: '',
                title: element.title,
                submenu: []
            };
            permissions.push(permission);
        });
        return permissions;
    }
    getPermissionBySearchCriteria(searchObj: any): Observable<any> {
        const observable = new Observable((observer) => {
            const request = { Name: searchObj.name, IsActive: searchObj.status };
            this.apiService.postData(PermissionService.GET_PATH, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const response: Response = {
                        failure: false, success: true,
                        result: this.buildPermissions(result.Permissions), error: null
                    };
                    observer.next(response);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = environment.errorMessages[error.error.Code || error.error.Status || error.error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
    getPermissionByRole(searchObj: any): Observable<any> {
        const observable = new Observable((observer) => {
            const request = { RoleId: searchObj};
            this.apiService.postData(PermissionService.GET_PATH5, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const response: Response = {
                        failure: false, success: true,
                        result: this.buildRolePermissions(result.Permissions), error: null
                    };
                    observer.next(response);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = environment.errorMessages[error.error.Code || error.error.Status || error.error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
    getRolesByPermissionId(searchObj: any): Observable<any> {
        const observable = new Observable((observer) => {
            const request = { Id: searchObj};
            this.apiService.postData(PermissionService.GET_PATH7, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const response: Response = {
                        failure: false, success: true,
                        result: this.buildRoles(result.SortByPermissions), error: null
                    };
                    observer.next(response);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = environment.errorMessages[error.error.Code || error.error.Status || error.error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
    private buildPermissions(permissions: Array<any>) {
        const permissionList = [];
        for (let index = 0; index < permissions.length; index++) {
            const permission = {
                sNo: index + 1,
                canCreate: permissions[index].CanCreate,
                canDelete: permissions[index].CanDelete,
                canMenuItem: permissions[index].CanMenuItem,
                canSubMenuItem: permissions[index].CanSubMenuItem,
                canUpdate: permissions[index].CanUpdate,
                canView: permissions[index].CanView,
                webIcon: permissions[index].WebIcon,
                webLink: permissions[index].WebUrl,
                sortBy: permissions[index].SortBy,
                name: permissions[index].Name,
                appLink: permissions[index].NavigatePage,
                appIcon: permissions[index].AppIcon,
                id: permissions[index].Id,
                parentId: permissions[index].parentId,
                notify: permissions[index].Notify,
                isActive: permissions[index].IsActive ? 'Yes' : 'No'
            };
            permissionList.push(permission);
        };
        return permissionList;
    }
    private buildRolePermissions(permissions: Array<any>) {
        const permissionList = [];
        for (let index = 0; index < permissions.length; index++) {
            const permission = {
                name: permissions[index].Name,
                id: permissions[index].Id,
                parentId: permissions[index].parentId,
                canMenuItem: permissions[index].CanMenuItem,
                canSubMenuItem: permissions[index].CanSubMenuItem,
                canCreate: permissions[index].CanCreate,
                canDelete: permissions[index].CanDelete,
                canUpdate: permissions[index].CanUpdate,
                canView: permissions[index].CanView,
                roleView: permissions[index].RoleView,
                roleCreate: permissions[index].RoleCreate,
                roleUpdate: permissions[index].RoleUpdate,
                roleDelete: permissions[index].RoleDelate,
                viewStatus: permissions[index].RoleView,
                createStatus: permissions[index].RoleCreate,
                updateStatus: permissions[index].RoleUpdate,
                deleteStatus: permissions[index].RoleDelate,
                viewId: permissions[index].CanViewId,
                createId: permissions[index].CanCreateId,
                updateId: permissions[index].CanUpdateId,
                deleteId: permissions[index].CanDeleteId,
                webLink: permissions[index].WebUrl,
                sortBy: permissions[index].SortBy,
                notify: permissions[index].Notify,
            };
            permissionList.push(permission);
        };
        return permissionList;
    }
    private buildRoles(roles: Array<any>) {
        const rolesList = [];
        for (let index = 0; index < roles.length; index++) {
            const role = {
                sNo: index + 1,
                role: roles[index].Name,
                id: roles[index].Id,
            };
            rolesList.push(role);
        };
        return rolesList;
    }
    getSortbyPermissions(): Observable<any> {
        let permissionUrl = ApiDictionary.getSortbyPermission.url;
        permissionUrl = Resources.getsortbypermission + permissionUrl;
        const param = this.apiService.BindParamsToUrl(permissionUrl, null);
        const observable = new Observable((observer) => {
            this.apiService.getData(param).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                } else {
                    const response: Response = {
                        failure: false, success: true,
                        result: this.buildSortByPermissions(result.SortByPermissions), error: null
                    };
                    observer.next(response);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = environment.errorMessages[error.error.Code || error.error.Status || error.error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    private buildSortByPermissions(permissions: Array<any>) {
        const permissionList = [];
        permissions.forEach(element => {
            const permission: SortByPermissions = {
                sortBy: element.SortBy,
                name: element.Name,
                id: element.Id
            };
            permissionList.push(permission);
        });
        return permissionList;
    }
    savePermission(saveObject: any): Observable<any> {
        const observable = new Observable((observer) => {
            const reqBody = this.buildSaveObject(saveObject);
            if (reqBody.length === 0) {
                const res: Response = {
                    failure: true, success: false, error: 'Atleast add read permission'
                };
                observer.next(res);
            } else {
                this.apiService.postData(PermissionService.SAVE_PATH, { 'SavePermissions': reqBody }).subscribe((result: any) => {
                    if (result instanceof HttpErrorResponse) {
                        const res: Response = {
                            failure: true, success: false,
                            error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                        };
                        observer.next(res);
                    } else {
                        const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                        observer.next(res);
                    }
                }, (error) => {
                    const res: Response = {
                        failure: true, success: false, error: null
                    };
                    if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                        res.error = error.error.Message;
                    } else {
                        res.error = environment.errorMessages[error.error.Code || error.error.Status || error.status];
                    }
                    observer.next(res);
                });
            }
        });
        return observable;
    }
    getSortbyPermissionStatus(): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.getDetails(PermissionService.GET_PATH1).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const response: Response = {
                        failure: false, success: true,
                        result: this.buildSortByPermissions(result.SortByPermissions), error: null
                    };
                    observer.next(response);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = environment.errorMessages[error.error.Code || error.error.Status || error.error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
    getSortbyPermissionStatusBySubmenu(object: any): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.getDetails(PermissionService.GET_PATH6).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const response: Response = {
                        failure: false, success: true,
                        result: this.buildSortByPermissions(result.SortByPermissions), error: null
                    };
                    observer.next(response);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = environment.errorMessages[error.error.Code || error.error.Status || error.error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
    getSortbySubPermissionStatus(menuId: number): Observable<any> {
        let permissionSetUrl = PermissionService.GET_PATH4;
        permissionSetUrl = permissionSetUrl + '/' + menuId;
        const observable = new Observable((observer) => {
            this.apiService.getDetails(permissionSetUrl).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const response: Response = {
                        failure: false, success: true,
                        result: this.buildSortByPermissions(result.SortByPermissions), error: null
                    };
                    observer.next(response);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = environment.errorMessages[error.error.Code || error.error.Status || error.error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
    buildSaveObject(saveObject: any): any {
        const permissions = Array<SavePermission>();
        permissions.push({
            Action: 'Get',
            Resource: saveObject.name, WebIcon: saveObject.webIcon,
            CanMenuItem: saveObject.canMenuItem,
            SortBy: saveObject.sortBy,
            IsActive: saveObject.canView,
            WebLink: saveObject.webLink,
            Id: saveObject.id || 0,
            Parent: saveObject.subSortBy,
        });
        permissions.push({
            Action: 'Post',
            Resource: saveObject.name,
            WebIcon: '',
            CanMenuItem: false,
            SortBy: 0,
            WebLink: '',
            Id: 0,
            Parent: 0,
            IsActive: saveObject.canCreate
        });
        permissions.push({
            Action: 'Put',
            Resource: saveObject.name,
            WebIcon: '',
            CanMenuItem: false,
            SortBy: 0,
            WebLink: '',
            Id: 0,
            Parent: 0,
            IsActive: saveObject.canUpdate
        });
        permissions.push({
            Action: 'Delete',
            Resource: saveObject.name,
            WebIcon: '',
            CanMenuItem: false,
            SortBy: 0,
            WebLink: '',
            Id: 0,
            Parent: 0,
            IsActive: saveObject.canDelete
        });
        return permissions;
    }

    updatePermission(saveObject: any): Observable<any> {
        const observable = new Observable((observer) => {
            const reqBody = this.buildSaveObject(saveObject);
            if (reqBody.length === 0) {
                const res: Response = {
                    failure: true, success: false, error: 'Atleast add read permission'
                };
                observer.next(res);
            } else {
                this.apiService.updateData(PermissionService.UPDATE_PATH, { 'SavePermissions': reqBody }).subscribe((result: any) => {
                    if (result instanceof HttpErrorResponse) {
                        const res: Response = { failure: true, error: result.error, success: false };
                        observer.next(res);
                    } else {
                        const res: Response = {
                            failure: false, error: null, success: true,
                            result: saveObject.name + environment.successMessages.Update_Success
                        };
                        observer.next(res);
                    }
                }, (error) => {
                    const res: Response = {
                        failure: true, success: false, error: null
                    };
                    if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                        res.error = error.error.Message;
                    } else {
                        res.error = environment.errorMessages[error.error.Code || error.error.Status || error.error.status];
                    }
                    observer.next(res);
                });
            }
        });
        return observable;
    }

    deletePermission(name: string, status: boolean): Observable<any> {
        let apiUrl = PermissionService.DELETE_PATH;
        apiUrl = apiUrl + '/' + name + '/' + (!status);
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = { failure: true, error: result.error, success: false };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: name + (!status
                            ? environment.successMessages.Activate_Success
                            : environment.successMessages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = environment.errorMessages[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
}


