import { Directive, ElementRef, HostListener, Input, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[numeric]'
})

export class NumericDirective {

    @Input('numericType') numericType: string; // number | decimal

    private regex = {
        number: new RegExp(/^\d+$/),
        decimal: new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g),
        decimals: new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g)
    };

    private specialKeys = {
        number: ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'],
        decimal: ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'],
        decimals: ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'],
    };

    constructor(private el: ElementRef, @Self() private ngControl: NgControl) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {

        if (this.specialKeys[this.numericType].indexOf(event.key) !== -1) {
            return;
        }
        // Do not use event.keycode this is deprecated.
        // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex[this.numericType])) {
            event.preventDefault();
        }
    }

    @HostListener('blur')
    onBlur() {
        if (this.ngControl && this.numericType === 'decimal') {
            let value = this.ngControl.value;
            value = value && value.indexOf('.') > 0 ? this.updatePriceValue(value) : (value ? value + '.00' : '');
            this.ngControl.control.setValue(value);
        }
        if (this.ngControl && this.numericType === 'decimals') {
            let value = this.ngControl.value;
            value = value && value.indexOf('.') > 0 ? this.updatePriceValues(value) : (value ? value + '.000' : '');
            this.ngControl.control.setValue(value);
        }
    }

    private updatePriceValue(value: any): string {
        if (!value.substr(value.indexOf('.') + 1)) {
           value = value + '00';
        } else if (value.substr(value.indexOf('.') + 1)) {
            value = value.substr(0, value.indexOf('.') + 1) + value.substr(value.indexOf('.') + 1, 2);
        }
        return value;
     }
     private updatePriceValues(value: any): string {
        if (!value.substr(value.indexOf('.') + 1)) {
           value = value + '000';
        } else if (value.substr(value.indexOf('.') + 1)) {
            value = value.substr(0, value.indexOf('.') + 1) + value.substr(value.indexOf('.') + 1, 3);
        }
        return value;
     }
}
