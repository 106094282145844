import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LayoutService } from '../services/layout.service';
import { Subscription, from } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { AuthService } from '../auth/auth.service';
import { RoutingService } from '../services/routing.service';
import { SharedService } from '../services/shared.Service';
import { StoreService } from '../services/store/store.service';
import { RolesService } from '../services/roles/roles.service';
import { Response } from '../../shared/models/response/response.model';
import { AlertService } from '../services/alert.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PermissionService } from '../services/permission/permission.service';
import { AppComponent } from 'app/app.component'
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [RolesService, StoreService]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('content', { static: false }) content;
  currentLang = 'en';
  toggleClass = 'ft-maximize';
  placement = 'bottom-right';
  selectedStore: any;
  selectedRole: any;
  private storeList: Array<any> = [];
  private roleList: Array<any> = [];
  public isCollapsed = true;
  layoutSub: Subscription;
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();
  public config: any = {};
  Name: any;
  constructor(public translate: TranslateService,
    private layoutService: LayoutService, private storeservice: StoreService, private appcomponent: AppComponent,
    private configService: ConfigService, private roleService: RolesService,
    private authService: AuthService, private alertService: AlertService,
    private router: Router, private modalService: NgbModal, private permissionServece: PermissionService,
    private sharedService: SharedService, private routing: RoutingService,
    private activatedRoute: ActivatedRoute) {
    this.selectedStore = ''; this.selectedRole = '';
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');
    this.Name = this.sharedService.userFullName;
    this.layoutSub = layoutService.changeEmitted$.subscribe(
      direction => {
        const dir = direction.direction;
        if (dir === 'rtl') {
          this.placement = 'bottom-left';
        } else if (dir === 'ltr') {
          this.placement = 'bottom-right';
        }
      });
  }

  ngOnInit() {
    this.config = this.configService.templateConf;
    this.getStores();
    this.getUserRoles();
  }

  ngAfterViewInit() {
    if (this.config.layout.dir) {
      setTimeout(() => {
        const dir = this.config.layout.dir;
        if (dir === 'rtl') {
          this.placement = 'bottom-left';
        } else if (dir === 'ltr') {
          this.placement = 'bottom-right';
        }
      }, 0);

    }
  }
  getStores(): void {
    const UserId = + this.sharedService.appUserId;
    this.storeservice.getUserStores(UserId).subscribe((response: Response) => {
      if (response.failure) {
        this.alertService.warnAlertAsync(response.error).subscribe();
      } else {
        if (response.result.length === 0 && this.sharedService.appUserId === '1' || this.sharedService.appUserId === '2') {
          this.storeList = [
            { id: 1, name: 'Default' },
          ];
        } else {
          this.storeList = response.result;
        }
      }
    });
  }
  getUserRoles(): void {
    const UserId = + this.sharedService.appUserId;
    this.roleService.getUserRoleByUserId(UserId).subscribe((response: Response) => {
      if (response.failure) {
        this.alertService.warnAlertAsync(response.error).subscribe();
      } else {
        this.roleList = response.result;
      }
    });
  }
  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  ChangeLanguage(language: string) {
    this.translate.use(language);
  }

  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else {
      this.toggleClass = 'ft-maximize';
    }
  }

  toggleNotificationSidebar() {
    this.layoutService.emitNotiSidebarChange(true);
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName('app-sidebar')[0];
    if (appSidebar.classList.contains('hide-sidebar')) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }
  openModel() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    this.modalService.open(this.content, ngbModalOptions);
  }
  onChange(newValue) {
    this.selectedStore = newValue;
    this.sharedService.storeId = this.selectedStore;
  }
  onRoleChange(newValue) {
    this.selectedRole = newValue;
    this.sharedService.sessionVar = this.selectedRole;
  }
  getPermissionsByRoleId(): void {
    const roleId = (this.sharedService.where(this.roleList, 'name', this.sharedService.sessionVar) || {}) as any;
    this.permissionServece.getPermissionByRoleId(roleId.id).subscribe((response: Response) => {
      if (response.failure) {
        this.alertService.warnAlertAsync(response.error).subscribe();
      } else {
        this.sharedService.permissionList = response.result;
        const url = this.routing.defaultRoutebyRole(this.sharedService.sessionVar);
        this.redirectTo(url);
      }
    });
  }
  redirectTo(uri: string) {
    this.router.navigateByUrl('//', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }
  close() {
    this.modalService.dismissAll();
    this.routing.sideMenus = [];
    this.routing.role = '';
    this.getPermissionsByRoleId();
  }
  logout() {
    this.authService.logout();
    this.routing.sideMenus = [];
    this.routing.role = '';
    this.appcomponent.idle.stop();
    this.router.navigate(['/'], { relativeTo: this.activatedRoute });
  }
}
