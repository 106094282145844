import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { environment } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Categories } from 'app/shared/models/categories/categories.model';
import { Response } from 'app/shared/models/response/response.model';
import { DatePipe } from '@angular/common';


@Injectable()
export class CategoryService {
    static GET_PATH = `${environment.apiPath}` + 'getcategorybysearchcriteria';
    static GET_PATH1 = `${environment.apiPath}` + 'getparentcategories';
    static SAVE_PATH = `${environment.apiPath}` + 'savecategory';
    static UPDATE_PATH = `${environment.apiPath}` + 'updatecategory';
    static DELETE_PATH = `${environment.apiPath}` + 'deletecategory';
    constructor(private apiService: ApiService, private datepipe: DatePipe) { }

    saveCategory(categories: Categories): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.postData(CategoryService.SAVE_PATH, categories).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = environment.errorMessages[error.error.Code || error.error.Status || error.error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    searchCategory(searchObj: any): Observable<any> {
        const observable = new Observable((observer) => {
            const request = {
                Name: searchObj.name, IsActive: searchObj.status,
                 ParentCategory : searchObj.parent === null ? searchObj.parent : searchObj.parent.CategoryId,
                FromDate: new Date(searchObj.fromdate.year, searchObj.fromdate.month - 1,
                    searchObj.fromdate.day).toDateString(),
                ToDate: new Date(searchObj.todate.year, searchObj.todate.month - 1,
                    searchObj.todate.day).toDateString(),
            };
            this.apiService.postData(CategoryService.GET_PATH, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateCategoryList(result.Catagories), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error:
                     environment.errorMessages[error.error.Message || error.error.Code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }
    getCategory(): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.getDetails(CategoryService.GET_PATH1).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    observer.next(result);
                }
            }, (error) => {
                const response: Response = { failure: true, error:
                    environment.errorMessages[error.error.Message || error.error.Code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }

    private populateCategoryList(catagories: any): any {
        const catalogList = [];
        for (let index = 0; index < catagories.length; index++) {
            const catalog = {
                sNo: index + 1,
                id: catagories[index].Id,
                name: catagories[index].Name,
                description: catagories[index].Description,
                isActive: catagories[index].IsActive ? 'Yes' : 'No',
                startDate: catagories[index].StartDate ,
                endDate: catagories[index].EndDate,
                rootCategory: catagories[index].RootCategory,
                parentCategoryId: catagories[index].ParentCategoryId,
                parentCategory: catagories[index].ParentCategory,
                longDescription: catagories[index].LongDescription,
                createdOn: this.datepipe.transform( catagories[index].CreatedOn, 'yyyy-MM-dd'),
            };
            catalogList.push(catalog);
        }
        return catalogList;
    }

    updateCategory(category: Categories): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.updateData(CategoryService.UPDATE_PATH, category).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = { failure: true, error: result.error, success: false };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true,
                        result: category.Name + environment.successMessages.Update_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = environment.errorMessages[error.error.Message || error.error.Code || error.error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    deleteCategory(id: number, status: boolean, name: string): Observable<any> {
        let apiUrl = CategoryService.DELETE_PATH;
        apiUrl = apiUrl + '/' + id + '/' + (!status);
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = { failure: true, error: result.error, success: false };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: name + (!status
                            ? environment.successMessages.Activate_Success
                            : environment.successMessages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = environment.errorMessages[error.error.Message || error.error.Code || error.Status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
}
