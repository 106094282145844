import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { BsModalRef, ModalDirective, BsModalService } from 'ngx-bootstrap/modal';
import { AppService } from './shared/services/session/AppService';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'environments/environment.defaults';
import { AuthService } from './shared/auth/auth.service';
import { RoutingService } from './shared/services/routing.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('childModal', { static: false }) childModal: ModalDirective;
  subscription: Subscription;
  idleState = 'Not started.'; timedOut = false; lastPing?: Date = null; title = 'user-idle-timeout'; public modalRef: BsModalRef;
  constructor(public idle: Idle, private keepalive: Keepalive, private authService: AuthService, private activatedRoute: ActivatedRoute,
    private router: Router, private modalService: BsModalService,  private routing: RoutingService,
     private appService: AppService, private routingService: RoutingService, ) {

    idle.setIdle(environment.sessionTimeout);   // sets an idle timeout of 900 seconds, for testing purposes.
    idle.setTimeout(10); // sets a timeout period of 10 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // sets the default interrupts,  things like clicks, scrolls, touches to the document

    idle.onIdleEnd.subscribe(() => {
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.childModal.hide();
      this.timedOut = true;
       this.logout();
    });
    idle.onIdleStart.subscribe(() => {
      this.childModal.show();
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'Your session is disconnecting In  ' + countdown + ' Seconds !. Do you want to continue the session ?'
      console.log(this.idleState);
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);
    keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.appService.getSesstion().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
        this.timedOut = false;
      } else {
        idle.stop();
      }
    })

  }
  ngOnInit() {
    // this.subscription = this.router.events
    //   .pipe(
    //     filter(event => event instanceof NavigationEnd)
    //   )
    //   .subscribe(() => window.scrollTo(0, 0));

    this.subscription =  this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((event: NavigationStart) => {
      const url = this.routingService.navigateTo(event);
      if (event.url !== url) {
        this.router.navigate([url], { relativeTo: this.activatedRoute });
      }
    });
  }
  reset() {
    this.idle.watch();
    this.timedOut = false;
  }
  hideChildModal(): void {
    this.childModal.hide();
  }
  stay() {
    this.childModal.hide();
    this.reset();
  }
  logout() {
    this.childModal.hide();
    this.idle.stop();
    this.routing.sideMenus = [];
    this.routing.role = '';
    this.authService.logout();
    this.appService.setUserLoggedIn(false);
    this.router.navigate(['/'], { relativeTo: this.activatedRoute });
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
