import { Injectable } from '@angular/core';
import { HttpParams, HttpRequest, HttpClient } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';


@Injectable()
export class ApiService {
    constructor(private http: HttpClient) { }
    BindParamsToUrl(requestTemplate: any, modelData: any): HttpRequest<any> {
        const url = requestTemplate.url ? requestTemplate.url : requestTemplate;
        const jsonparams = requestTemplate.params;
        let search = new HttpParams();
        if (modelData) {
            Object.keys(jsonparams).forEach(element => {
                search = search.set(element, modelData[element]);
            });
        }
        const options = new HttpRequest<any>('GET', url, { params: search });
        return options;
    }
    getData(options: HttpRequest<any>): Observable<Object> {
        return this.http.get(options.url, { params: options.params });
    }
    getDetails(url: string): Observable<Object> {
        return this.http.get(url);
    }

    postData(url: string, body: object): Observable<Object> {
        return this.http.post(url, body);
    }

    updateData(url: string, body: object): Observable<Object> {
        return this.http.put(url, body);
    }

    deleteData(url: string): Observable<Object> {
        return this.http.delete(url);
    }
}

