export const ApiDictionary = {
   getUserDetails: {
      url: 'getuserdetails',
      params: null
   },
   getProducts: {
      url: 'getproducts',
      params: null
   },
   getUserProducts: {
      url: 'getproducts',
      params: null
   },
   getProductNames: {
      url: 'getproductnames',
      params: null
   },
   getProductImagesById: {
      url: 'getproductimagesbyproductid',
      params: { id: 0 }
   },
   saveProduct: {
      url: 'saveproduct',
      params: null
   },
   updateProduct: {
      url: 'updateproduct',
      params: null
   },
   deleteProduct: {
      url: 'deleteproduct',
      params: { id: 0, status: false }
   },
   getProductDetails: {
      url: 'getproductdetails',
      params: { id: 0 }
   },
   getPermissionSet: {
      url: 'permissionset',
      params: { userId: '', isMobileRequest: false }
   },
   getRoles: {
      url: 'getrolesbysearchcriteria',
      params: null
   },
   saveRole: {
      url: 'addrole',
      params: null
   },
   updateRole: {
      url: 'updaterole',
      params: null
   },
   deleteRole: {
      url: 'deleterole',
      params: { id: 0, status: false }
   },
   getRoleGroups: {
      url: 'getrolegroups',
      params: null
   },
   saveRoleGroups: {
      url: 'saveupdaterolegroups',
      params: null
   },
   getPermissions: {
      url: 'getpermissions',
      params: null
   },
   getSortByPermissions: {
      url: 'getsortbypermissions',
      params: null
   },
   getSortbyPermission: {
      url: 'getsortbypermission',
      params: null
   },
   savePermission: {
      url: 'savepermission',
      params: null
   },
   updatePermission: {
      url: 'updatepermission',
      params: null
   },
   deletePermission: {
      url: 'deletepermission',
      params: null
   },
   saveCatalog: {
      url: 'savecatalog',
      params: null
   },
   getCatalogSearch: {
      url: 'getcatalogbysearchcriteria',
      params: null
   },
   getUserCatalogSearch: {
      url: 'getusercatalogbysearchcriteria',
      params: null
   },
   deleteCatalog: {
      url: 'deletecatalog',
      params: {
         id: 0, status: false
      }
   },
   updateCatalog: {
      url: 'updatecatalog',
      params: null
   },
   getBrandSearch: {
      url: 'getbranddetails',
      params: null
   },
   saveBrand: {
      url: 'savebrand',
      params: null
   },
   updateBrand: {
      url: 'updatebrand',
      params: null
   },
   deleteBrand: {
      url: 'deletebrand',
      params: {
         id: 0, status: false
      }
   },
   getCategorySearch: {
      url: 'getcategorybysearchcriteria',
      params: null
   },
   saveCategory: {
      url: 'savecategory',
      params: null
   },
   updateCategory: {
      url: 'updatecategory',
      params: null
   },
   deleteCategory: {
      url: 'deletecategory',
      params: {
         id: 0, status: false
      }
   },
   getMediaSearch: {
      url: 'getmediabysearchcriteria',
      params: null
   },
   saveMedia: {
      url: 'savemedia',
      params: null
   },
   updateMedia: {
      url: 'updatemedia',
      params: null
   },
   deleteMedia: {
      url: 'deletemedia',
      params: {
         id: '', status: false
      }
   },
   getProductOptionSearch: {
      url: 'getproductoptionbysearchcriteria',
      params: null
   },
   saveProductOption: {
      url: 'saveproductoption',
      params: null
   },
   updateProductOption: {
      url: 'updateproductoption',
      params: null
   },
   deleteProductOption: {
      url: 'deleteproductoption',
      params: { id: 0 }
   },
   getUserRoles: {
      url: 'getuserroles',
      params: {
         id: 0
      }
   },
   saveUserRoles: {
      url: 'adduserrole',
      params: null
   },
   saveUserDetails: {
      url: 'saveuserdetails',
      params: null
   },
   getPortalUserDetails: {
      url: 'getuserattributes',
      params: null,
   },
   savePortalUserDetails: {
      url: 'saveuser',
      params: null
   },
   updatePortalUserDetails: {
      url: 'updateuser',
      params: null
   },
   deletePortalUserDetails: {
      url: 'disableuser',
      params: {
         userName: '', status: false
      }
   },
   getUserSettings: {
      url: 'getusersettings',
      params: null
   },
   saveUserSettings: {
      url: 'saveusersettings',
      params: null
   },
   saveSmsMasterSettings: {
      url: 'smsmastersettings',
      params: null
   },
   saveEmailMasterSettings: {
      url: 'emailmastersettings',
      params: null
   }
};
