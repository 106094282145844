import { Routes } from '@angular/router';

// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('../../calendar/calendar.module').then(m => m.CalendarsModule)
  },
  {
    path: 'productoption',
    loadChildren: () => import('../../modules/productoption/productoption.module').then(m => m.ProductOptionModule)
  },
  {
    path: 'user',
    loadChildren: () => import('../../modules/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'roles',
    loadChildren: () => import('../../modules/roles/roles.module').then(m => m.RolesModule)
  },
  {
    path: 'category',
    loadChildren: () => import('../../modules/categories/categories.module').then(m => m.CategoriesModule)
  },
  {
    path: 'ordersales',
    loadChildren: () => import('../../modules/sale/sale.module').then(m => m.ItemsSalesModule)
  },
  {
    path: 'orderestimation',
    loadChildren: () => import('../../modules/sales/sales.module').then(m => m.SalesModule)
  },
  {
    path: 'ordersalesreturn',
    loadChildren: () => import('../../modules/salesreturn/salesreturn.module').then(m => m.SalesReturnModule)
  },
  {
    path: 'product',
    loadChildren: () => import('../../modules/product/product.module').then(m => m.ProductModule)
  },
  {
    path: 'orderpurchase',
    loadChildren: () => import('../../modules/purchase/purchase.module').then(m => m.PurchaseModule)
  },
  {
    path: 'orderpurchasereturn',
    loadChildren: () => import('../../modules/purchasereturn/purchase.return.module').then(m => m.PurchaseReturnModule)
  },
  {
    path: 'stock',
    loadChildren: () => import('../../modules/stock/stock.module').then(m => m.StockModule)
  },
  {
    path: 'orderinvoice',
    loadChildren: () => import('../../modules/order-invoice/order-invoice.module').then(m => m.OrderInvoiceModule)
  },
  {
    path: 'transferinvoice',
    loadChildren: () => import('../../modules/transfer-Invoice/transfer-invoice.module').then(m => m.TransferInvoiceModule)
  },
  {
    path: 'store',
    loadChildren: () => import('../../modules/store/store.module').then(m => m.StoreModule)
  },
  {
    path: 'permission',
    loadChildren: () => import('../../modules/permission/permission.module').then(m => m.PermissionModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('../../modules/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'accountgroup',
    loadChildren: () => import('../../modules/accounts/accountgroup/pages/accountgroup.module').then(m => m.AccountGroupModule)
  },
  {
    path: 'accounthead',
    loadChildren: () => import('../../modules/accounts/accounthead/pages/accounthead.module').then(m => m.AccountHeadModule)
  },
  {
    path: 'account',
    loadChildren: () => import('../../modules/accounts/account/pages/account.module').then(m => m.AccountModule)
  },
  {
    path: 'hsncode',
    loadChildren: () => import('../../modules/producthsncode/producthsncode.module').then(m => m.ProductHSNCodeModule)
  },
  {
    path: 'charts',
    loadChildren: () => import('../../charts/charts.module').then(m => m.ChartsNg2Module)
  },
  {
    path: 'forms',
    loadChildren: () => import('../../forms/forms.module').then(m => m.FormModule)
  },
  {
    path: 'maps',
    loadChildren: () => import('../../maps/maps.module').then(m => m.MapsModule)
  },
  {
    path: 'tables',
    loadChildren: () => import('../../tables/tables.module').then(m => m.TablesModule)
  },
  {
    path: 'datatables',
    loadChildren: () => import('../../data-tables/data-tables.module').then(m => m.DataTablesModule)
  },
  {
    path: 'uikit',
    loadChildren: () => import('../../ui-kit/ui-kit.module').then(m => m.UIKitModule)
  },
  {
    path: 'components',
    loadChildren: () => import('../../components/ui-components.module').then(m => m.UIComponentsModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
  },
  {
    path: 'cards',
    loadChildren: () => import('../../cards/cards.module').then(m => m.CardsModule)
  },
  {
    path: 'colorpalettes',
    loadChildren: () => import('../../color-palette/color-palette.module').then(m => m.ColorPaletteModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('../../chat/chat.module').then(m => m.ChatModule)
  },
  {
    path: 'chat-ngrx',
    loadChildren: () => import('../../chat-ngrx/chat-ngrx.module').then(m => m.ChatNGRXModule)
  },
  {
    path: 'inbox',
    loadChildren: () => import('../../inbox/inbox.module').then(m => m.InboxModule)
  },
  {
    path: 'taskboard',
    loadChildren: () => import('../../taskboard/taskboard.module').then(m => m.TaskboardModule)
  },
  {
    path: 'taskboard-ngrx',
    loadChildren: () => import('../../taskboard-ngrx/taskboard-ngrx.module').then(m => m.TaskboardNGRXModule)
  },
  {
    path: 'player',
    loadChildren: () => import('../../player/player.module').then(m => m.PlayerModule)
  },
];
