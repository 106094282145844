const SuperAdminPermissions = [
    {
        name: 'Users', title: 'Users', link: ['/users'], sortBy: 1, canCreate: true,
        canEdit: true, canDelete: true, canView: true, canMenuItem: true, icon: 'ion ion-ios-contact'
    }, {
        name: 'Profile', title: 'Profile', link: ['/profile'], sortBy: 1, canCreate: true,
        canEdit: true, canDelete: false, canView: true, canMenuItem: true, icon: 'ion ion-ios-contact'
    }, {
        name: 'Logout', title: 'Logout', link: ['/logout'], sortBy: 1, canCreate: true,
        canEdit: true, canDelete: false, canView: true, canMenuItem: true, icon: 'ion ion-log-out'
    }];
const BackendAdminPermissions = [
    {
        name: 'Users', title: 'Users', link: ['/users'], sortBy: 1, canCreate: true,
        canEdit: true, canDelete: true, canView: true, canMenuItem: true, icon: 'ion ion-ios-contact'
    }, {
        name: 'Profile', title: 'Profile', link: ['/profile'], sortBy: 1, canCreate: true,
        canEdit: true, canDelete: false, canView: true, canMenuItem: true, icon: 'ion ion-ios-contact'
    }, {
        name: 'Logout', title: 'Logout', link: ['/logout'], sortBy: 1, canCreate: true,
        canEdit: true, canDelete: false, canView: true, canMenuItem: true, icon: 'ion ion-log-out'
    }];
const OperatorPermissions = [
    {
        name: 'Users', title: 'Users', link: ['/users'], sortBy: 1, canCreate: true,
        canEdit: true, canDelete: false, canView: true, canMenuItem: true, icon: 'ion ion-ios-contact'
    }, {
        name: 'Profile', title: 'Profile', link: ['/profile'], sortBy: 1, canCreate: true,
        canEdit: true, canDelete: false, canView: true, canMenuItem: true, icon: 'ion ion-ios-contact'
    }, {
        name: 'Logout', title: 'Logout', link: ['/logout'], sortBy: 1, canCreate: true,
        canEdit: true, canDelete: false, canView: true, canMenuItem: true, icon: 'ion ion-log-out'
    }];

export const PermissionSet = {
    SuperAdminPermissionset: SuperAdminPermissions,
    BackendAdminPermissionset: BackendAdminPermissions,
    OperatorPermissionset: OperatorPermissions
};

