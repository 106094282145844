import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Response } from '../models/response/response.model';
import { SharedService } from '../services/shared.Service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  static AUTH_PATH = `${environment.apiPath}` + 'login';
  static Refresh_PATH = `${environment.apiPath}`;
  constructor(private apiService: ApiService, private sharedService: SharedService) { }

  signupUser(email: string, password: string) {
    // your code for signing up the new user
  }

  signinUser(userName: string, password: string) {
    const observable = new Observable((observer) => {
      const req = {
        UserName: userName,
        Password: btoa(password)
      };
      this.apiService.postData(AuthService.AUTH_PATH, req).subscribe((response: any) => {
        if (response instanceof HttpErrorResponse) {
          const res: Response = {
            failure: true, success: false,
            error: response.status === 400 ? response.error : environment.errorMessages[response.status]
          };
          observer.next(res);
        } else {
          this.sharedService.token = response.Token;
          this.sharedService.refreshToken = response.RefreshToken;
          this.sharedService.appUserId = response.UserId;
          this.sharedService.userFullName = response.Name;
          const res: Response = {
            failure: false, success: true,
            error: null, result: response
          };
          observer.next(res);
        }
      }, (err: HttpErrorResponse) => {
        const res: Response = {
          failure: true, success: false,
          error: ''
        };
        if (err.error && err.error.Code && err.error.Code.indexOf('400') > -1) {
          res.error = err.error.Message;
        } else {
          res.error = environment.errorMessages[err.status]
        }
        observer.next(res);
      });
    });
    return observable;
  }

  logout() {
    this.sharedService.clear();
  }

  getToken() {
    return this.sharedService.token;
  }
  getRefreshToken() {
    let apiUrl = AuthService.Refresh_PATH;
    apiUrl = apiUrl + this.sharedService.refreshToken + '/' + 'refresh';
    const observable = new Observable((observer) => {
      this.apiService.getDetails(apiUrl).subscribe((result) => {
        if (result instanceof HttpErrorResponse) {
          const res: Response = {
            failure: true, success: false,
            error: result.status === 400 ? result.error : environment.errorMessages[result.status]
          };
          observer.next(res);
        } else {
          const res: Response = {
            failure: false, success: true,
            result: result, error: null
          };
          observer.next(res);
        }
      }, (error) => {
        const response: Response = { failure: true, error: environment.errorMessages[error.error.error || 
          error.error.code], success: false };
        observer.next(response);
      });
    });
    return observable;
  }

  isAuthenticated() {
    // here you can check if user is authenticated or not through his token
    return !!this.sharedService.token;
  }
}
