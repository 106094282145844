import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { environment } from 'environments/environment';
import { Response } from 'app/shared/models/response/response.model';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';


@Injectable()
export class MasterSettingsService {
    static GET_PATH = `${environment.apiPath}` + 'getdbverstion';
    constructor(private apiService: ApiService) { }
    getDbVerstion(): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.getDetails(MasterSettingsService.GET_PATH).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = {
                        failure: true, error: environment.errorMessages[error.error.Code || error.error.Message],
                        success: false
                    };
                }
                observer.next(res);
            });
        });
        return observable;
    }
}
