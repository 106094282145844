import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { environment } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Store} from 'app/shared/models/store/store.model';
import { Response } from 'app/shared/models/response/response.model';


@Injectable()
export class StoreService {
    static GET_PATH = `${environment.apiPath}` + 'getstorebysearchcriteria';
    static GET_PATH1 = `${environment.apiPath}` + 'getstoresbyid';
    static GET_PATH2 = `${environment.apiPath}` + 'getusersbystoreid';
    static SAVE_PATH = `${environment.apiPath}` + 'savestore';
    static UPDATE_PATH = `${environment.apiPath}` + 'updatestore';
    static DELETE_PATH = `${environment.apiPath}` + 'deletestore';
    constructor(private apiService: ApiService) { }

    saveStore(store: Store): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.postData(StoreService.SAVE_PATH, store).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = { failure: false, error: null, success: true, result: result.StringValue };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = environment.errorMessages[error.error.Code || error.error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }

    searchStore(searchObj: any): Observable<any> {
        const observable = new Observable((observer) => {
            const request = {
                StoreName: searchObj.storeName, IsActive: searchObj.isActive
            };
            this.apiService.postData(StoreService.GET_PATH, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateStoreList(result.Store), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = { failure: true, error: environment.errorMessages[error.Code || error.code], success: false };
                observer.next(response);
            });
        });
        return observable;
    }
    getUserStores(searchObj: any): Observable<any> {
        const observable = new Observable((observer) => {
            const request = {
                UserId: searchObj
            };
            this.apiService.postData(StoreService.GET_PATH1, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateUserStoreList(result.UserStores), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = {
                    failure: true, error: error.error.Message , success: false };
                observer.next(response);
            });
        });
        return observable;
    }
    getUsersByStoreId(searchObj: any): Observable<any> {
        const observable = new Observable((observer) => {
            const request = {
                StoreId: searchObj
            };
            this.apiService.postData(StoreService.GET_PATH2, request).subscribe((result: any) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = {
                        failure: true, success: false,
                        error: result.status === 400 ? result.error : environment.errorMessages[result.status]
                    };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true,
                        result: this.populateUsersList(result.Users), error: null
                    };
                    observer.next(res);
                }
            }, (error) => {
                const response: Response = {
                    failure: true, error: error.error.Message , success: false };
                observer.next(response);
            });
        });
        return observable;
    }
    private populateUserStoreList(stores: any): any {
        const userList = [];
        for (let index = 0; index < stores.length; index++) {
            const user = {
                id : stores[index].Id,
                name : stores[index].Name,
            };
            userList.push(user);
        }
        return userList;
    }
    private populateUsersList(stores: any): any {
        const storeList = [];
        for (let index = 0; index < stores.length; index++) {
            const store = {
                id : stores[index].Id,
                name : stores[index].Name,
            };
            storeList.push(store);
        }
        return storeList;
    }
    private populateStoreList(stores: any): any {
        const storeList = [];
        for (let index = 0; index < stores.length; index++) {
            const store = {
                sNo : index + 1,
                storeId : stores[index].StoreId,
                storeName : stores[index].StoreName,
                companyName : stores[index].CompanyName,
                companyAddress : stores[index].CompanyAddress,
                companyPhoneNo : stores[index].CompanyPhoneNumber ,
                companyGst : stores[index].CompanyGST,
                latitude : stores[index].Latitude,
                logitude : stores[index].Logitude,
                isActive : stores[index].IsActive ? 'Yes' : 'No',
                updatedOn : stores[index].UpdatedOn
            };
            storeList.push(store);
        }
        return storeList;
    }

    updateStore(store: Store): Observable<any> {
        const observable = new Observable((observer) => {
            this.apiService.updateData(StoreService.UPDATE_PATH, store).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = { failure: true, error: result.error, success: false };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, error: null, success: true,
                        result: store.StoreName + environment.successMessages.Update_Success
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (error.error.Code && error.error.Code.indexOf('400') > -1) {
                    res.error = error.error.Message;
                } else {
                    res.error = { failure: true, error: environment.errorMessages[error.Code || error.code], success: false };
                }
                observer.next(res);
            });
        });
        return observable;
    }

    deleteStore(storeId: number, isActiveStatus: boolean, storeName: string): Observable<any> {
        let apiUrl = StoreService.DELETE_PATH;
        apiUrl = apiUrl + '/' + storeId + '/' + (!isActiveStatus);
        const observable = new Observable((observer) => {
            this.apiService.deleteData(apiUrl).subscribe((result) => {
                if (result instanceof HttpErrorResponse) {
                    const res: Response = { failure: true, error: result.error, success: false };
                    observer.next(res);
                } else {
                    const res: Response = {
                        failure: false, success: true, error: null,
                        result: storeName + (!isActiveStatus
                            ? environment.successMessages.Activate_Success
                            : environment.successMessages.Deactivate_Success)
                    };
                    observer.next(res);
                }
            }, (error) => {
                const res: Response = {
                    failure: true, success: false, error: null
                };
                if (!error.Code && error.Code.indexOf('400') > -1) {
                    res.error = error.Message;
                } else {
                    res.error = environment.errorMessages[error.Code || error.Status || error.status];
                }
                observer.next(res);
            });
        });
        return observable;
    }
}
